import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 福岡",
          date: "2021年09月30日",
          title: (
            <>
              ザ ロイヤルパークホテル 福岡に泊まる。
              <br />
              明るさに包まれた「ちょうど良さ」
            </>
          ),
          text: <>ペンネーム：十六日</>,
          img: {
            src: "/assets/images/journal/article01/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article01/kv__sp.png",
          },
        }}
      />
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>ちょうど良い街・福岡</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  <p>「福岡ってどんな街ですか？」</p>
                  <p>
                    タクシーの運転手や飲食店の店員、バーテンダー、ホテルのスタッフ。この街で言葉を交わしたさまざまな人々にそんな問いを投げかけた。
                  </p>
                  <p>
                    「なんでもあるし、適度に都会ですね」
                    <br />
                    「食べ物が美味しいし、物価が安いよ」
                    <br />
                    「明るくて、お人好しが多いね」
                  </p>
                  <p>
                    もらった返答は、おおむねそういったものだった。私が福岡に滞在していた日々に感じとった街の雰囲気に関して言えば、たしかにその通りだったと言えるだろう。
                  </p>
                  <p>
                    人口約160万人。九州最大の都市であり、日本の都市のなかでも、東京23区を除くと5番目の人口を持つ大都市。プロ野球の球団だってあるし、Jリーグのチームだってある。明太子やもつ鍋、とんこつラーメンといったご当地の名物料理は日本全国どこでだって人気だ。
                  </p>
                  <p>
                    最近では若い世代の移住先として選ばれることが多く、2020年の転入超過は2019年のなんと2倍。全国に20ある政令指定都市の中でもっとも人口を増やした都市となった。アジア圏という広い範囲で見ても、その勢いは指折りである。
                  </p>
                  <p>
                    歴史があるのに、伸び盛りで若々しい都市。それでいて、住む人たちはおおらかで人情味がある。そう、「ちょうど良い」のだ、福岡という街は。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>宿なんてどこも同じ、</span>
                <span>じゃない？</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  <p>「福岡、行こう」</p>
                  <p>
                    私が妻にそう言ったのは、実際に行くことになった1か月前だった。福岡のあるホテルから、宿泊記を執筆してほしいという、ありがたい依頼があったのだ。以前から、「いつか福岡に行こうよ」と話してはいた。「美味しいものをたくさん食べようよ」と。私も妻も、福岡を訪れたことがなかった。二つ返事で依頼を引き受けることにした。
                  </p>
                  <p>
                    宿を選ぶときには、さまざまな選択肢がある。
                    <br />
                    たとえば、私たちのようにまだ若者気分が抜けない新婚夫婦なら、ホステルの個室に泊まるのも悪くない。少し奮発して、ラグジュアリーな高級ホテルで贅沢をするのもいい。食事を心置きなく楽しむために、ビジネスホテルで節約するのも現実的だ。
                  </p>
                  <p>「でも結局、宿なんてどこに泊まっても同じじゃない？」</p>
                  <p>
                    そう思っていた私は、宿泊することになったホテルのオフィシャルサイトを見た。
                    <br />ザ ロイヤルパークホテル 福岡。
                    <br />
                    サイトには「街と、もてなす。」というキャッチコピーが掲げられている。値段はそれなりに手頃だし、評判もいい。写真で見る限り部屋もなかなか綺麗そうだ。今年でオープン10周年だという。めでたい。縁起がよさそうだ。
                  </p>
                  <p>私たちは、旅の支度を始めた。</p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>いざ、福岡へ。そして宿へ</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  新幹線やJR線、市営地下鉄線が通る博多駅からザ
                  ロイヤルパークホテル
                  福岡までの距離は、徒歩で約5分である。ちなみに、福岡空港から博多駅までも、地下鉄で5分ほどしかかからない。首都圏の距離感を基準にすると、驚くほど近い。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter03_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    博多エリアにはたくさんのホテルが立ち並んでいる。その中にあって、飛び抜けて目立つような派手派手しさがあるわけではないが、割合新しい建物であるがゆえのクリーンさと、スタンダードな親しみやすさ、そして品の良さを備えるのがザ
                    ロイヤルパークホテル 福岡の外観だ。
                  </p>
                  <p>
                    明るくてきぱきとしていながら、和やかさを感じさせるフロントでチェックインを済ませる。いわゆるビジネスホテルも含む宿泊主体型のホテルであるものの、館内にはほどよい高級感と清潔さが目立っていた。
                  </p>
                  <p>
                    エレベーターで宿泊階を選ぶためには、フロントで受け取ったカードキーが必要だ。私のようなせっかちな人間にとってはやや面倒に感じる部分もあったが、ホテルのセキュリティ意識の高さへの安心感もあった。
                  </p>
                  <p>
                    いざ部屋へ。
                    <br />
                    私は旅行用の大きなバックパックを床に置いて、ベッドに倒れ込んだ。大の字になって手足を伸ばす。ホテルに宿泊する際、もっとも喜びを感じる瞬間だ。妻はキャリーケースを開けて身の回り品を取り出しながら、部屋をチェックし始めた。
                  </p>
                  <p>
                    部屋はそれほど広くないけれど、窮屈さも感じない。ちょうど良い広さの、清潔で落ち着いた空間。壁紙の模様がヨーロピアンテイストでさりげなくエレガントだ。館内で無料使用できるWi-Fiも問題なく繋がった。
                  </p>
                  <p>
                    特筆したいのが浴室の広さ。リーズナブルな価格帯の部屋でありながら、浴槽でゆっくり身体を休めることができるだろう。妻も「お風呂が綺麗！広い！」と喜んでいた。洗面台と浴室はセパレートされていて、浴室ではレインシャワーも使うことができる。アメニティはオリジナルの包装で、特別感がある。
                  </p>
                  <p>
                    私たちは部屋で旅の疲れをしばし休めて、外で早めの夕飯をとることにした。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>福岡といえば、</span>
                <span>もつ鍋と明太子</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    もつ鍋のルーツは第二次世界大戦後、不要な部分として処分されることが多かったもつ肉をニラと共に醤油で味付けして、アルミ鍋で炊いて食べていたことにあると言われている。
                  </p>
                  <p>
                    私たちが訪れたのは、創業からまもなく30年を迎えようとしている、もつ鍋の有名店「博多もつ鍋
                    やま中」本店だった。もつ鍋と辛子明太子を注文した。
                  </p>
                  <p>
                    辛子明太子の「明太」とは朝鮮語でスケトウダラのことを指す。もともと朝鮮半島にはスケトウダラのたらこと唐辛子を使った料理があったのだそう。博多の食料品店であった「ふくや」の創業者が、釜山で食べたその料理を再現するために試行錯誤を重ね、現在の「辛子明太子」の原型となるものを開発した。「明太子」の名前をつけて、日本で初めて販売し始めたのが1957年。それから追随するつくり手が増え始め、博多は明太子の聖地となった。
                  </p>
                  <p>
                    もつ鍋と明太子。どちらも戦後に広まった、比較的新しい郷土料理と言えるだろう。だからこそだろうか。高級すぎず、気さくで若々しい。私たちはお腹をふくらませ、満ち足りた面持ちで「やま中」を後にした。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter05.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter05__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "05",
            },
            title: (
              <>
                <span>オープンしたばかりの</span>
                <span>バーへ。</span>
                <span>季節のカクテルを味わう。</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  ザ ロイヤルパークホテル
                  福岡に戻った私たちが向かったのは、1階にあるバーである「THE
                  BAR」だった。
                  <br />
                  ムーディーで落ち着いていながら、カジュアルで居心地のいいバーはオープンしたばかり。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter05_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  目を引くのは、カウンターの背後にずらりと並べられた酒類の瓶たち。ライトアップされて中の液体をきらめかせる瓶たちの存在感に、思わず「かっこいい……」と呟いてしまった。ちなみに店内にはワインセラーが設置されており、近年人気を集めるオレンジワインも用意されているという。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter05_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    カウンター席に座って、バーテンダーの方に話しかけた。私たちは最近結婚したばかりの夫婦で、福岡は初めての訪問であること。バーテンダーさんは、黙々とカクテルを作りながら、私たちの話に耳を傾けてくれた。福岡で生まれ育ったという彼に、「福岡のサラリーマンは普段どんな場所でお酒を飲むんですか？」とか「こちらの中高生はどこらへんでデートするんですか？」とか、とりとめのない質問を投げかけると、大名や天神、中洲といった福岡市内の各エリアについて親切に教えてくれた。
                  </p>
                  <p>
                    おすすめのメニューを聞くと、「秋」を意識したオリジナルカクテル3種を紹介してくれた。
                  </p>
                  <p>
                    1種類目は美しいオレンジ色が特徴的な「秋色」。フルーティーでありながら甘すぎず、さわやかな飲み味だ。
                  </p>
                  <p>
                    妻がもっとも気に入っていたのが「大葉のジン」。すりつぶした大葉を大胆に使ったカクテルで、ビジュアルのインパクトも大。こちらもさわやかである。
                  </p>
                  <p>
                    私が気に入ったのは、「オータムハーベスト」。カボスやグレープフルーツといった柑橘系の果汁とウォッカを使ったカクテルで、コップのふちにはピンクソルトが付く。濃厚かつさわやかなソルティドッグといったところだ。飲みやすいけれどウォッカベースのしっかりとしたお酒なので、飲み過ぎには注意しよう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter05_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    このバーでは季節の素材を使ったオリジナルカクテルを作り、ハロウィンやクリスマスといったイベントを彩るメニューも用意する予定だという。
                  </p>
                  <p>
                    こだわりのポイントを聞くと、食後に立ち寄ることを想定しているため、舌にさわやかさを感じてもらえるよう、すっきりした味を意識していると教えてくれた。細やかな心遣いが素敵だった。
                  </p>
                  <p>
                    「個人的には居酒屋の気楽さが好き」だとこっそり教えてくれたバーテンダーさんは、「バーというと堅苦しくてハードルが高いイメージがあるが、初心者でも気軽に立ち寄ってほしい」と語ってくれた。このバーは宿泊客でなくても利用可能だ。
                  </p>
                  <p>
                    バーを後にして、ほろ酔いで部屋まで戻った私たちは、広い浴槽でゆったりと入浴をした。ベッドは高級ホテルにも導入されているシモンズ製だった。誇張抜きで、こんなにも深い眠りを味わったのは久しぶりだった。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter06.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter06__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "06",
            },
            title: (
              <>
                <span>朝食のために腹を</span>
                <span>空かせておいたほうがいい</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    いくら本場だからといって、明太子をこんなに食べられるなんて。
                  </p>
                  <p>
                    ホテル1階の中華レストラン「Precious ONO
                    HAKATA」では、ブッフェスタイルで朝食が提供されるのだが、こんな罪深い皿を見つけてしまった。なんと、明太子が食べ放題なのだ。ビジュアル的にもなかなかインパクトがあった。
                  </p>
                  <p>
                    このほかにも朝食の魅力的な点はたくさんあった。
                    <br />
                    たとえば、着席時に注文することができる中華粥や、〆の塩ラーメン。
                    <br />
                    塩ラーメンは優しくて品のある味。ラーメン好きの私としては朝からラーメンを食べられる喜びに浸っていた。ヘルシー志向の妻は中華粥が気に入ったようだった。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter06_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ブッフェコーナーには中華を中心とした約30種類の料理が並ぶ。朝から中華と聞いてやや驚いたが、食べてみると重くないし、和洋の料理もあって選ぶのが楽しい。このレストランは福岡に拠点を置き、和食からオーベルジュまでさまざまなジャンルの個性豊かなレストランを展開するONOグループが運営しているもので、一品ごとのクオリティは折り紙付きである。普通の「ホテルの朝食」のレベルをイメージすると、やや面食らうかもしれない。
                  </p>
                  <p>
                    「けっこう美味い」「いや、かなりおいしい」などと妻と言い合いながら食べていたら、朝食なのに食べすぎてしまった。罪深い明太子も、もちろん思う存分盛り付けた。福岡には私たちが思っている以上に明太子がたくさんあるのかもしれない。
                  </p>
                  <p>
                    大満足で部屋に戻り、荷物をまとめて、チェックアウトの準備をした。なんとなく名残惜しい。
                  </p>
                  <p>「宿なんてどこに泊まっても同じじゃない？」</p>
                  <p>
                    そんなふうに思っていた数日前の自分に言いたいのは、「どこに泊まっても同じってことは、ないよ」という至極当たり前のことだ。
                  </p>
                  <p>
                    フロントでにこやかに見送られて、私たちはザ
                    ロイヤルパークホテル 福岡を後にした。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "07",
            },
            title: (
              <>
                <span>「ちょうど良い街」福岡を</span>
                <span>散策。バスが便利</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    宿を出た私たちが向かったのは、昨夜バーテンダーさんから教えてもらった福岡市内の各所。天神や大名といったあたりを散策した。
                  </p>
                  <p>
                    事前に聞いていたとおり、福岡市はコンパクトにさまざまなエリアがまとまった都市だ。九州最大の繁華街である天神、小さな商店や飲食店が集まって活気がある大名、屋台で有名な飲食店街・中洲、そして福岡市の中心部であり、驚くほど大きな駅もある博多。そういった個性的なエリアがそれぞれ徒歩圏内で隣接している。
                  </p>
                  <p>
                    市内にバス網が張り巡らせられていて、エリア間の移動には苦労しない。また福岡市内を走る西鉄バスのアプリは出来がよく、現在地から目的地を選べば、最寄りのバス停や発着時刻を教えてくれる。直感的に使うことができるので、おすすめだ。
                  </p>
                  <p>
                    コンパクトといっても、狭さを感じるわけではない。むしろ綺麗に舗装された広々した道が多く、歩いているだけでも気分がいい。そう、この街は「ちょうど良い」のだ。
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "08",
            },
            title: (
              <>
                <span>「よそ者」を受け入れる、</span>
                <span>気さくな人たち</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    博多は日本最古の港町の1つである。
                    <br />
                    古代から朝鮮半島や大陸との交易で栄え、稲作が日本に初めて伝わったのも博多だと言われている。
                    <br />
                    長い長い歴史を持つ港町。だからだろうか、博多の人たちは「よそ者」に優しい。ユニークで風変わりな人やアイデアだって受け入れてくれるような、懐の深さを感じる。
                  </p>
                  <p>
                    たとえばタクシーの運転手さんたちや、コンビニの店員さんたち。ちょっとした受け答えや応対のなかに、明るく嫌味のない心遣いを感じた。とくにタクシー運転手の方々は、ちょっとおせっかいなくらい、気さくに街のことを教えてくれた。
                  </p>
                  <p>
                    こんなエピソードがあった。私たちが「博多もつ鍋
                    やま中」に行こうとしていたときのことだ。タクシーの配車アプリで目的地を「やま中」と設定した私たちだったが、じつは福岡には「鮨割烹
                    やま中」という、こちらも人気がある寿司屋が存在していて、私たちは間違った目的地を指定してしまっていたのだ。しかしタクシーの運転手さんは私たちの会話から誤りを教えてくれて、もつ鍋のほうの「やま中」へと送り届けてくれたのだった。
                  </p>
                  <p>
                    街全体が「よそ者」である私たち夫婦を受け入れてくれていた。そんなふうに感じた1泊2日だった。
                  </p>
                  <p>
                    さて、これで今回の旅は終わり、というわけではなかった。1つ、確認したいことがあったのだ。私と妻の意見は一致していた。
                  </p>
                  <p>「ホテルにランチを食べに行こう」</p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter09.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter09__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "09",
            },
            title: (
              <>
                <span>ああ、</span>
                <span>やっぱりランチも・・・・・</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ザ ロイヤルパークホテル 福岡にあるレストラン「Precious ONO
                    HAKATA」のランチは、よほどの大食いでなければ間違いなく満足できる量と質を備えている。
                  </p>
                  <p>
                    メインとなるメニューを2品もしくは3品選ぶことができ、さらに約30種の料理を揃えたブッフェメニュー、スープバー、ドリンクバーが付く。メイン料理を2品選ぶと1890円、3品選ぶと2390円である。なんとリーズナブル。色とりどりの皿が並ぶブッフェで、薬膳粥を見つけた妻が喜んでいた。
                  </p>
                  <p>
                    メイン料理では私が「四川青山椒の担々麺」と「名物!
                    低温レア青椒牛肉絲」を注文。妻は「小海老のオレンジマヨネーズソース」と「豚バラ肉の黒酢角煮酢豚」を頼んだ。
                  </p>
                  <p>
                    四川青山椒の担々麺は予想以上に本格的で、ピリリと辛い逸品だった。低温レア青椒牛肉絲は「名物」と謳うだけあって気合の入った品。素材の旨味を巧みに引き出して、高いレベルで調和させていた。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter09_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ああ、やっぱりランチも美味かった。私たちは腹をさすりながら、すっかり満足してしまった。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article01/img_chapter10.png",
            },
            imgSp: {
              src: "/assets/images/journal/article01/img_chapter10__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "10",
            },
            title: (
              <>
                <span>福岡って、こんな街</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ところで私がホテルに舞い戻って「確認したかったこと」は、ランチの味だけではなかった。このホテルの館内に充ちた雰囲気をもう一度、肌で感じたかったのだ。
                  </p>
                  <p>
                    明るくて開放的、それでいて清潔な館内。旅の人たちをあたたかく迎え入れて、もてなしてくれるスタッフたち。食べ物は美味しくて、食べきれないくらいの料理を用意してくれる。高級ホテルみたいにラグジュアリーではないけれど、安心できて、しっくり来る。ちょうど良い。
                  </p>
                  <p>
                    この宿は、福岡の街そのものみたいだ。そう私は思った。確認したかったことは、それだった。
                  </p>
                  <p>「福岡ってどんな街ですか？」</p>
                  <p>私が聞かれたら、どう答えるだろう。</p>
                  <p>
                    「なんでもあるし、適度に都会ですね」
                    <br />
                    「食べ物が美味しいし、物価が安いよ」
                    <br />
                    「明るくて、お人好しが多いね」
                  </p>
                  <p>たしかにその通り。けれど私は、もう1つ付け加えたい。</p>
                  <p>「ちょうど良い宿がある、ちょうど良い街」</p>
                  <p>私にとって福岡は、そんな街だった。</p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article01/img_chapter10_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2021年9月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 福岡</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/fukuoka/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
